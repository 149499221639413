import React from 'react';

export default props => (
    <a href="index2.html" className="logo">
    {/*  mini logo for sidebar mini 50x50 pixels  
    <span className="logo-mini"><img src="dist/img/logo.png" alt="Texto"/></span>
    */}
    <span className="logo-mini">{props.mine_label}</span>
    {/*  logo for regular state and mobile devices  */}
    {/*<span className="logo-lg">{props.label}</span>
    <span className="logo-lg"><img src="dist/img/logo-large.png" alt="Texto"/></span>
    */}
    <span className="logo-lg">{props.label}</span>
  </a>

)

