import act from '../main/action-const'

const INITIAL_STATE = { 
    id_pessoa: 0,
    nome: '',
    classe: '',
    agente: '0',
    foto_file: null,
    documento: '',
    celular: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    uf: '',
    bairro: '',
    cidade: '',
    quest: [],
    perguntas: [],
    avaliacao: [],
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.FICHA_CARREGADA:
            return { ...state, ...action.payload }

        case act.FICHA_QUEST_CARREGADOS:
            return { ...state, quest: action.payload }

        case act.FICHA_PERGUNTAS_CARREGADAS:
            return { ...state, perguntas: action.payload }

        case act.FICHA_AVALIACAO_CARREGADAS: {
            let dados = action.payload
            let aval = []
            if (dados['aval_nota_1'] && dados['aval_nota_1'] >=0 ) 
                aval.push({
                    nota: dados['aval_nota_1'], 
                    peso: dados['aval_1_peso'] || 1,
                    titulo: dados['aval_1'] || '1º Avaliação'
                 })

            if (dados['aval_nota_2'] && dados['aval_nota_2'] >=0 ) 
                aval.push({
                    nota: dados['aval_nota_2'], 
                    peso: dados['aval_2_peso'] || 1,
                    titulo: dados['aval_2'] || '2º Avaliação'
                 })

            if (dados['aval_nota_3'] && dados['aval_nota_3'] >=0 ) 
                aval.push({
                    nota: dados['aval_nota_3'], 
                    peso: dados['aval_3_peso'] || 1,
                    titulo: dados['aval_3'] || '3º Avaliação'
                 })

            if (dados['aval_nota_4'] && dados['aval_nota_4'] >=0 ) 
                aval.push({
                    nota: dados['aval_nota_4'], 
                    peso: dados['aval_4_peso'] || 1,
                    titulo: dados['aval_4'] || '4º Avaliação'
                 })

            if (dados['aval_nota_r'] && dados['aval_nota_r'] >=0 ) 
                aval.push({
                    nota: dados['aval_nota_r'], 
                    peso: (dados['aval_r_peso'] || 1) === 9 ? 'Sobrepõe Média' :  (dados['aval_r_peso'] || 1),
                    titulo: dados['aval_r'] || 'Recuperação'
                })

            return { ...state, avaliacao: aval }
        }
    
        default:
            return state
    }
}
