import React from 'react'
import ControlSidebarMenu from './control-sidebar-menu'
//import ControlSidebarMenuItem from './control-sidebar-menu-item'
//import ControlSidebarMenuItemIcon from './control-sidebar-menu-item-icon'
//import ProgressBar from './progress-bar'
//import ControlSidebarGroupCheck from './control-sidebar-group-check'

export default props => (
  <>
    {/* Control Sidebar  */}
    <aside className="control-sidebar control-sidebar-dark">
        {/* Create the tabs */}
        <ul className="nav nav-tabs nav-justified control-sidebar-tabs">
        <li className="active"><a href="#control-sidebar-home-tab" data-toggle="tab"><i className="fa fa-info"></i></a></li>
        </ul>
        {/* Tab panes */}
        <div className="tab-content">

        {/* Home tab content */}
        <div className="tab-pane active" id="control-sidebar-home-tab">
            {/*<span className="logo-lg"><img src="dist/img/logo-large.png" alt="Logo"/></span>*/}
            <ControlSidebarMenu title="Em breve novidades!" >
            </ControlSidebarMenu>

        </div>
        {/* /.tab-pane */}


        {/* /.tab-pane */}
        </div>
    </aside>
    {/* /.control-sidebar */}
    {/* <!-- Add the sidebar's background. This div must be placed
              immediately after the control sidebar */}
    <div className="control-sidebar-bg"></div>
  </>
)