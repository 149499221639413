import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { InputGroup, FormRow } from '../common/form'
import { CalloutWarning } from '../common/msg/callout'
import Modal from '../common/ui/modal'

import { trocaremail } from '../ficha/ficha-action'

const validarFormulario  = (values) => {
    const erros = {}

    if ( !values.email || values.email.length === 0 )
        erros.email = 'Informe o novo e-mail'

    return erros
}


class PessoaTrocaEmail extends Component {

    constructor(props) {
        super(props);
        this.props.change("email", '')
    }

    onSubmit(values) {
        let vo = {}
        vo['id_pessoa'] = this.props.ficha['id_pessoa']
        vo['email'] = values.email
        this.props.trocaremail(vo)
    }

    render() {

        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='pessoa-troca-email-modal' 
                title={'Troca de Email'} 
                confirmLabel='Trocar'
                confirmButtonType='submit'
                onConfirm={()=>{ return true }}
                onClose={()=>{return true}}
            >
                <FormRow>
                    <Field label='Novo Email' name="email" type="email" required placeholder="email" disabled={false}  component={InputGroup}  cols='md-12' />
                </FormRow>
                <div className="clearfix"></div>

                <CalloutWarning title='Atenção!'>
                    <p>Trocar o e-mail de pessoas que acessam o sistema, implica também em trocar o usuário desta pessoa.
                    Portanto, ao fazer isso a pessoa receberá um novo convite para acessar o sistema.</p>
                </CalloutWarning>
                <div className="clearfix"></div>

            </Modal>
            </form>
        )
    }

}

PessoaTrocaEmail = reduxForm({
    form: 'pessoaTrocaEmailForm',
    validate: validarFormulario
})(PessoaTrocaEmail)


const mapStateToProps = state => ({
    ficha: state.ficha,
})
const mapDispatchToProps = dispatch => bindActionCreators({trocaremail}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(PessoaTrocaEmail)

