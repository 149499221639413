import React from 'react'
import { LabelInfo } from '../common/ui/labels'

export default props => (
    <>
    {(props.classe || '').includes('admin') && < LabelInfo title='Controle'/>}
    {(props.classe || '').includes('apoio') && < LabelInfo title='Apoio'/>}
    {(props.classe || '').includes('comercial') && < LabelInfo title='Comercial'/>}
    {(props.classe || '').includes('finan') && < LabelInfo title='Financeiro'/>}
    {(props.classe || '').includes('agente') && < LabelInfo title='Agente'/>}

    {(props.classe || '').includes('chofer') && < LabelInfo title='Chofer'/>}
    {(props.classe || '').includes('manobrista') && < LabelInfo title='Manobrista'/>}
    {(props.classe || '').includes('movifrota') && < LabelInfo title='Movi.Frota'/>}
    {(props.classe || '').includes('transporte') && < LabelInfo title='Transporte'/>}
    {(props.classe || '').includes('limpeza') && < LabelInfo title='Limpeza'/>}

    </>
)
