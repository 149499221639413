import React from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '../helper'

export const BoxTitle = props => {
    let border
    if (typeof props.border === "undefined") {
        border = true
    }
    return (
        <div className={`box ${props.add_class}`}>
            <div className={`box-header ${border ? 'with-border' : ''}`}>
                <h3 className="box-title">{props.title || 'Sem título'}
                </h3>
                {props.render && props.render()}
            </div>
            {props.children}
        </div>
    )
}

const box_tools = (tools) => {
    return (tools || []).map((vo, index) => {
        if (vo['on_click'])
            return (
                <button key={index} type="button" className="btn btn-box-tool"
                    onClick={(e) => { vo['on_click'] && vo['on_click'](e) }}
                ><i className={vo['icon'] || 'fa  fa-cog'}></i></button>
            )

        if (vo['route'])
            return (
                <Link key={index} to={vo['route']} className="btn btn-box-tool"
                ><i className={vo['icon'] || 'fa  fa-cog'}></i>
                </Link>
            )

        return ('')
    })

}

export const BoxWidget = props => (
    <div className={`box box-widget ${props.collapsed && 'collapsed-box'}`}>
        <div className="box-header with-border">
            <div className="user-block">
                {/*<img className="img-circle" src="../dist/img/m.jpg" alt="m" />*/}
                <span className="username">{props.title || ''}</span>
                <span className="description">{props.subtitle || ''}</span>
            </div>
            <div className="box-tools">
                {box_tools(props.tools)}
                <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className={`fa ${props.collapsed ? 'fa-plus' : 'fa-minus'}`}></i></button>
            </div>
        </div>
        {props.children}
    </div>
)


export const BoxSolid = props => (
    <div className="box box-solid">
        <div className="box-header with-border">
            <div className="user-block">
                <span className="username">{props.title || ''}</span>
                <span className="description">{props.subtitle || ''}</span>
            </div>
        </div>
        {props.children}
    </div>
)




export const Box = props => (
    <div className={`box ${props.add_class}`}>
        {props.children}
    </div>
)


export const BoxHeader = props => (
    <div className="box-header with-border">
        {props.children}
    </div>
)


export const BoxBody = props => (
    <div className="box-body">
        {props.children}
    </div>
)


export const BoxFooter = props => (
    <div className="box-footer">
        {props.children}
    </div>
)


export const BoxSimple = props => (
    <Box>
        <BoxBody>
            {props.children}
        </BoxBody>
    </Box>
)


export const BoxFooterComments = props => (
    <div className="box-footer box-comments">
        {props.children}
    </div>
)

export const BoxComments = props => (
    <div className="box-comment">
        { props.image && <img className="img-circle img-sm" src={props.image} alt="Usuário" />}

        <div className="comment-text">
            {props.title &&
                <span className="username">
                    {props.route && <Link to={props.route} > {props.title} </Link>}
                    {!props.route && props.title}
                    {props.right && <span className="text-muted pull-right">{props.right}</span>}
                    {props.tools && <span className="text-muted pull-right">{box_tools(props.tools)}</span>}
                </span>
            }
            {props.text}
            {props.children}
            {!props.title && props.tools && <span className="text-muted pull-right">{box_tools(props.tools)}</span>}
        </div>
    </div>
)


export const BoxGroup = props => (
    <div className="box-header with-border">
        <div className="user-block">
            <img className="img-circle" src={props.image || "../dist/img/profile.jpg"} alt="Usuário" />
            <span className="username">{props.title}</span>
            <span className="description">{props.description}</span>
        </div>
    </div>
)

export const BoxGroup2 = props => (
    <div className="box-comment">
        <img className="img-circle img-sm" src={props.image || "../dist/img/profile.jpg"} alt="usuário" />

        <div className="comment-text">
            <span className="username">
                Nora Havisham
                <span className="text-muted pull-right">8:03 PM Today</span>
            </span>
        The point of using Lorem Ipsum is that it has a more-or-less
        normal distribution of letters, as opposed to using
        'Content here, content here', making it look like readable English.
        </div>
    </div>
)

export const GroupUnbordered = props => (
    <ul className="list-group list-group-unbordered">
        { props.children}
    </ul>
)

export const GroupItem = props => (
    <li className="list-group-item" style={{ overflow: 'hidden' }}>

        {props.icon && <i className={`fa ${props.icon} margin-r-5`} />}
        {props.label && <b>{props.label}</b>}
        {props.value && <Anchor className="pull-right">{props.value}</Anchor>}
        {props.children}
    </li>
)
