import React from 'react'

export const Badge = (props) => (
    <span className={`pull-right margin-r-5 badge ${props.color_class}`}>
        {props.title}
        { props.children }
    </span>
)


export const BadgeBlue = (props) => (
    <Badge color_class="bg-blue" {...props}>
        { props.children }
    </Badge>
)

export const BadgeAqua = (props) => (
    <Badge color_class="bg-aqua" {...props}>
        { props.children }
    </Badge>
)


export const BadgeGreen = (props) => (
    <Badge color_class="bg-green" {...props}>
        { props.children }
    </Badge>
)


export const BadgeRed = (props) => (
    <Badge color_class="bg-red" {...props}>
        { props.children }
    </Badge>
)

export const BadgeYellow = (props) => (
    <Badge color_class="bg-yellow" {...props}>
        { props.children }
    </Badge>
)
