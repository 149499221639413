import React from 'react'

export const LabelSucess = (props) => (
    <span className={`label label-success margin-r-5 ${props.align_class|| ''}`}>{props.title}</span>
)

export const LabelWarning = (props) => (
    <span className={`label label-warning margin-r-5${props.align_class|| ''}`}>{props.title}</span>
)

export const LabelInfo = (props) => (
    <span className={`label label-info margin-r-5 ${props.align_class|| ''}`}>{props.title}</span>
)

export const LabelDanger = (props) => (
    <span className={`label label-danger margin-r-5 ${props.align_class|| ''}`}>{props.title}</span>
)

export const LabelDefault = (props) => (
    <span className={`label label-default margin-r-5 ${props.align_class|| ''}`}>{props.title}</span>
)
